import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from './client.service';
import { ToastrService } from 'ngx-toastr';

// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../../language_configure'

@Injectable()
export class AuthService {
    // Logout Path
    public navigateSign = '/';
    // Toastr
    public toastrConfig = {
        timeOut: 1500,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
    };
    
    private http_auth = null;
    /**
     * Authentication service constructor
     *
     * @param { Router } router - Router library
     * @param { ClientService } client - HTTP client Service
     */
    constructor(
        public router: Router,
        public client: ClientService,
        private toastr: ToastrService,
        public translate: TranslateService,
    ) { 
        language.init(
            translate,
            null,
            (value => {
              this.translate.get('http').subscribe( data => {
                this.http_auth = data;
              });
            })
        );    
    }

    /**
     * Make a signin request
     *
     * @param { string } mail - User email *Required
     * @param { string } password - User password *Required
     * @param { Function } destiny - Function to deal with HTTP response
     */
    public signin(mail: string, password: string, destiny: Function): void {
        const data = {
            'mail': mail,
            'password': password,
        };

        this.client.post('intranet/requisitions/ReqLogin.php', data, (response: any) => {
            destiny(response);
        });
    }

    /**
     * Make a signin request
     *
     * @param { string } mail - User email *Required
     * @param { string } password - User password *Required
     * @param { Function } destiny - Function to deal with HTTP response
     */
    public recover(mail: string, destiny: Function): void {
        const data = {
            'mail': mail,
        };

        this.client.post('intranet/requisitions/ReqLostPassword.php', data, (response: any) => {
            destiny(response);
        });
    }

    /**
     * Make a signout
     */
    public signout(destiny: Function): void {
        sessionStorage.removeItem('ddd');
        sessionStorage.removeItem('gender');
        sessionStorage.removeItem('is_active');
        sessionStorage.removeItem('mail');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('office');
        sessionStorage.removeItem('phone');
        sessionStorage.removeItem('pk');
        sessionStorage.removeItem('registration_date');
        sessionStorage.removeItem('surname');
        sessionStorage.removeItem('url_picture');
        sessionStorage.removeItem('permissions');
        destiny();
    }

    /**
     * Save User
     */
    public saveUser(data: any): void {
        // User Data
        sessionStorage.setItem('access_token', Date());
        sessionStorage.setItem('ddd', data['ddd']);
        sessionStorage.setItem('gender', data['gender']);
        sessionStorage.setItem('is_active', data['is_active']);
        sessionStorage.setItem('mail', data['mail']);
        sessionStorage.setItem('name', data['name']);
        sessionStorage.setItem('office', data['office']);
        sessionStorage.setItem('phone', data['phone']);
        sessionStorage.setItem('pk', data['pk']);
        sessionStorage.setItem('registration_date', data['registration_date']);
        sessionStorage.setItem('surname', data['surname']);
        sessionStorage.setItem('url_picture', data['url_picture']);
        sessionStorage.setItem('office_pk', data['office_pk']);
        sessionStorage.setItem('permissions', JSON.stringify(data['permissions']));
    }

    /**
     * Verify User
     */
    public verifyUser(): void {
        const token = Boolean(JSON.parse(sessionStorage.getItem('is_active')));
        // // TODO: expire token
        if (token === false) {
            this.signout(() => {
                this.toastr.warning('Está sessão expirou', 'Aviso!', this.toastrConfig);
                this.toastr.info(
                    this.http_auth['auth']['expire']['description'], 
                    this.http_auth['auth']['expire']['title'], 
                    this.toastrConfig
                );
                this.router.navigate([this.navigateSign]);
            });
        }
    }
}
