import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponseBase, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { ApiConfig } from '../../models/api-config';

/**
 *  Celero Shared Services httpClient client
 *
 *  Author: Eduardo Machado
 *
 *  Created at: 06/06/2018
 */
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  /**
   * API URL
   */
  public layerUrl = ApiConfig.HTTP_METHOD + ApiConfig.ENDPOINT;

  /**
   * Client service constructor
   *
   * @param { HttpClient } http - Angular HTTP Client service
   * @param { Router } router - Router library
   */
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }


  /**
   * Remove keys without data from a request
   *
   * @param { any } dictionary - Request data
   *
   * @returns { any } - Clened data
   */
  public clearRequest(dictionary: any): any {
    if (typeof dictionary !== 'object') {
      return dictionary;
    }

    const cleaned = {};

    for (const key in dictionary) {
      if (
        (dictionary[key] !== undefined) &&
        (dictionary[key] !== null) &&
        (dictionary[key] !== 'null') &&
        (dictionary[key] !== '')
      ) {

        if (dictionary[key] instanceof Array) {
          const cleanedList = [];

          for (const object of dictionary[key]) {
            cleanedList.push(this.clearRequest(object));
          }

          cleaned[key] = cleanedList;
        } else if (dictionary[key] instanceof Object) {
          cleaned[key] = this.clearRequest(dictionary[key]);
        } else {
          cleaned[key] = dictionary[key];
        }

      } else if (dictionary[key] === 'null') { // force to send null
        cleaned[key] = null;
      }
    }
    return cleaned;
  }

  /**
   * HTTP POST method
   *
   * @param url - API route
   * @param data - data dictionary
   * @param destiny - Function to deal with HTTP response
   */
  public post(url: string, _data: {}, destiny: Function): void {
    let token = '';

    if (localStorage.getItem('access_token')) {
      token = localStorage.getItem('access_token');
    }

    const httpHeaders = new HttpHeaders({
      "Authorization"		    :	token,
      // "Accept"			      :	"*/*",
      // "Content-Type"      :	"application/json, text/plain",
    });

    this.http.post(this.layerUrl + url, this.clearRequest(_data), {
      headers: httpHeaders,
      observe: 'response'
    })
      .subscribe(
        (response: HttpResponseBase) => {
          if (response.headers.get('Authorization')) {
            localStorage.setItem('access_token', response.headers.get('Authorization'));
          }
          destiny(response);
        },
        (error: HttpResponseBase) => {
          if (error.headers['Authorization']) {
            localStorage.setItem('access_token', error.headers['Authorization']);
          }
          if (error.status === 400) {
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': error.status });
          } else if (error.status === 403) {
            this.redirectToLoginPage();
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[2], {
            //   'Status code': error.status,
            //   'url': error.url,
            // });
          }
          destiny(error);
        }
      );
  }

  /**
   * HTTP POST for Uploads method
   *
   * @param url - API route
   * @param data - data dictionary
   * @param destiny - Function to deal with HTTP response
   */
  public postUpload(url: string, data: any, destiny: Function): void {
    let token = '';

    if (sessionStorage.getItem('access_token')) {
      token = sessionStorage.getItem('access_token');
    }

    const httpHeaders = new HttpHeaders({
      'Authorization': token
    });

    this.http.post(this.layerUrl + url, data, {
      headers: httpHeaders,
      observe: 'response'
    })
      .subscribe(
        (response: HttpResponseBase) => {
          if (response.headers.get('token')) {
            sessionStorage.setItem('access_token', response.headers.get('token'));
          }
          if (response.status === 400) {
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': response.status });
            console.log(response.status);
          } else if (response.status === 403) {
            this.redirectToLoginPage();
          }
          destiny(response);
        },
        (error: HttpResponseBase) => {
          if (error.headers['token']) {
            sessionStorage.setItem('access_token', error.headers['token']);
          }
          if (error.status === 400) {
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': error.status });
          } else if (error.status === 403) {
            this.redirectToLoginPage();
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[2], {
            //   'Status code': error.status,
            //   'url': error.url,
            // });
          }
          destiny(error);
        }
      );
  }

  /**
   * HTTP PUT method
   *
   * @param url - API route
   * @param data - data dictionary
   * @param destiny - Function to deal with HTTP response
   */
  public put(url: string, data: {}, destiny: Function): void {
    let token = '';

    if (sessionStorage.getItem('access_token')) {
      token = sessionStorage.getItem('access_token');
    }

    const httpHeaders = new HttpHeaders({
      'Authorization': token
    });

    this.http.put(this.layerUrl + url, this.clearRequest(data), {
      headers: httpHeaders,
      observe: 'response'
    })
      .subscribe(
        (response: HttpResponseBase) => {
          if (response.headers.get('token')) {
            sessionStorage.setItem('access_token', response.headers.get('token'));
          }
          if (response.status === 400) {
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': response.status });
            console.log(response.status);
          } else if (response.status === 403) {
            this.redirectToLoginPage();
          }
          destiny(response);
        },
        (error: HttpResponseBase) => {
          if (error.headers['token']) {
            sessionStorage.setItem('access_token', error.headers['token']);
          }
          if (error.status === 400) {
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': error.status });
            console.log(error.status);
          } else if (error.status === 403) {
            this.redirectToLoginPage();
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[2], {
            //   'Status code': error.status,
            //   'url': error.url,
            // });
          }
          destiny(error);
        }
      );
  }

  /**
   * HTTP GET method
   *
   * @param url - API route
   * @param params - params dictionary
   * @param destiny - Function to deal with HTTP response
   */
  public get(url: string, params: {}, destiny: Function): void {
    let token = '';

    if (sessionStorage.getItem('access_token')) {
      token = sessionStorage.getItem('access_token');
    }

    const httpHeaders = new HttpHeaders({
      'Authorization': token
    });

    this.http.get(this.layerUrl + url, {
      headers: httpHeaders,
      params: this.clearRequest(params),
      observe: 'response'
    })
      .subscribe(
        (response: HttpResponseBase) => {
          if (response.headers.get('token')) {
            sessionStorage.setItem('access_token', response.headers.get('token'));
          }
          if (response.status === 400) {
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': response.status });
            console.log(response.status);
          } else if (response.status === 403) {
            this.redirectToLoginPage();
          }
          destiny(response);
        },
        (error: HttpResponseBase) => {
          if (error.headers['token']) {
            sessionStorage.setItem('access_token', error.headers['token']);
          }
          if (error.status === 400) {
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': error.status });
          } else if (error.status === 403) {
            this.redirectToLoginPage();
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[2], {
            //   'Status code': error.status,
            //   'url': error.url,
            // });
          }
          destiny(error);
        }
      );
  }

  /**
   * HTTP DELETE method
   *
   * @param url - API route
   * @param params - params dictionary
   * @param destiny - Function to deal with HTTP response
   */
  public delete(url: string, params: {}, destiny: Function): void {
    let token = '';

    if (sessionStorage.getItem('access_token')) {
      token = sessionStorage.getItem('access_token');
    }

    const httpHeaders = new HttpHeaders({
      'Authorization': token
    });

    this.http.delete(this.layerUrl + url, {
      headers: httpHeaders,
      params: this.clearRequest(params),
      observe: 'response'
    },
    )
      .subscribe(
        (response: HttpResponseBase) => {
          if (response.headers.get('token')) {
            sessionStorage.setItem('access_token', response.headers.get('token'));
          }
          if (response.status === 400) {
            console.log(response.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': response.status });
          } else if (response.status === 403) {
            this.redirectToLoginPage();
          }
          destiny(response);
        },
        (error: HttpResponseBase) => {
          if (error.headers['token']) {
            sessionStorage.setItem('access_token', error.headers['token']);
          }
          if (error.status === 400) {
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[1], { 'Status code': error.status });
          } else if (error.status === 403) {
            this.redirectToLoginPage();
            console.log(error.status);
            // this.mixpanel.track(this.mixpanelModel.tracks[2], {
            //   'Status code': error.status,
            //   'url': error.url,
            // });
          }
          destiny(error);
        }
      );
  }

  /**
   * Redirects to login page when user receive a 403
   */
  private redirectToLoginPage(): void {
    this.router.navigate(['/auth']);
  }
}
