export class ValidatorPermissions {
  public validateSubPermission(master: string, sub: string): boolean {
    return true;
  }

  public validatePermission(master: string): boolean {
    let permissions = JSON.parse(sessionStorage.getItem('permissions'));
    if(permissions[master] === undefined){
      return true;
    }
    return permissions[master];
    // finances: true
    // mobile_users: true
    // notification: true
    // partners: true
    // politicy: true
    // system: true
    // terms: true
    // tickets: true
    // opção:  undefined init
    // validator-permissions.ts:12 opção:  undefined mobile_users
    // validator-permissions.ts:12 opção:  true partners
    // validator-permissions.ts:12 opção:  true tickets
    // validator-permissions.ts:12 opção:  undefined notification
    // validator-permissions.ts:12 opção:  true terms
    // validator-permissions.ts:12 opção:  undefined politicy
    // validator-permissions.ts:12 opção:  true finances
    // validator-permissions.ts:12 opção:  true system
  }
}