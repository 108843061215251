import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/httpClient/auth.service';
import { ToastrService } from 'ngx-toastr';

// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../language_configure'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit, AfterViewInit {

  // Inputs
  @Input() mail: string;
  @Input() password: string;
  @Input() recover_mail: string;

  public date = new Date();

  // Navigation Config
  public navigateSign = '/dashboard';

  // Toastr
  public toastrConfig = {
    timeOut: 1500,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
  };

  // Loading Config
  public customLoadingTemplate: any;
  public loading = false;

  private http_auth = null;

  public is_login = true;

  constructor(
    public router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService,
  ) {
    language.init(
      translate,
      null,
      (value => {
        this.translate.get('http').subscribe( data => {
          this.http_auth = data;
        });
      })
    );
  }

  public _text: string;
  ngOnInit() {
    sessionStorage.removeItem('access_token');
  }

  ngAfterViewInit() {
    $(function () {
      $('.preloader').fadeOut();
    });
  }

  public tappedRecover(): void {
    this.is_login = false;
    $('#loginform').slideUp();
    $('#recoverform').fadeIn();
  }

  public tappedLogin(): void {
    this.is_login = true;
    $('#loginform').slideDown();
    $('#recoverform').fadeOut();
  }

  public validateSign(): boolean {
    if (this.mail && this.password) {
      return true;
    } else {
      return false;
    }
  }

  public validateRecover(): boolean {
    if (this.recover_mail) {
      return true;
    } else {
      return false;
    }
  }

  public sign(): void {
    this.loading = true;
    this.auth.signin(this.mail, this.password, (response) => {
      if (response.status === 200) {
        if(response.body['status'] === true) {
          this.toastr.success(
            this.http_auth['auth']['login']['description'], 
            this.http_auth['auth']['login']['title'], 
            this.toastrConfig
          );
          this.auth.saveUser(response.body['data']);
          this.router.navigate([this.navigateSign]);
        } else {
          this.toastr.error(
            response.body['error'], 
            this.http_auth['auth']['login-error']['title'], 
            this.toastrConfig
          );
        }
      } else if (response.status === 400) {
        this.toastr.error(
          this.http_auth['error_400']['description'], 
          this.http_auth['error_400']['title'], 
          this.toastrConfig
        );
      } else if (response.status >= 500) {
        this.toastr.error(
          this.http_auth['error_500']['description'], 
          this.http_auth['error_500']['title'], 
          this.toastrConfig
        );
      } else {
        this.toastr.error(
          response.error['non_field_errors'],
          this.http_auth['error_unknown']['title'],
          this.toastrConfig
        );
      }

      this.loading = false;
    });
  }

  public recover(): void {
    this.loading = true;
    this.auth.recover(this.recover_mail, (response) => {
      if (response.status === 200) {
        if(response.body['status'] === true) {
          this.toastr.success(
            this.http_auth['auth']['recover_password']['description'], 
            this.http_auth['auth']['recover_password']['title'], 
            this.toastrConfig
          );
          this.tappedLogin();
        } else {
          this.toastr.error(
            response.body['error'], 
            this.http_auth['auth']['login-error']['title'], 
            this.toastrConfig
          );
        }
      } else if (response.status === 400) {
        this.toastr.error(
          this.http_auth['error_400']['description'], 
          this.http_auth['error_400']['title'], 
          this.toastrConfig
        );
      } else if (response.status >= 500) {
        this.toastr.error(
          this.http_auth['error_500']['description'], 
          this.http_auth['error_500']['title'], 
          this.toastrConfig
        );
      } else {
        this.toastr.error(
          response.error['non_field_errors'],
          this.http_auth['error_unknown']['title'],
          this.toastrConfig
        );
      }

      this.loading = false;
    });
  }
}

