export class ApiConfig {

  showDarktheme: false;

  /**
   * Static repository URL
   */
  private static readonly static_url = 'localhost/WEB/RedWindow/';

  /**
   * Static Staging URL
   */
  private static readonly static_staging_url = 'localhost/WEB/RedWindow/';

  /**
   * Returns the current HTTP method
   */
  public static get HTTP_METHOD(): string {
    if (this.IS_PRODUCTION) {
      return 'https://';
    } else {
      return 'http://';
    }
  }

  /**
   * Is production method
   *
   * @returns { boolean } - Returns if is current in production
   */
  public static get IS_PRODUCTION(): boolean {
    if (window.location.host === 'intranet.freelajobs.com.br' || window.location.host === 'www.intranet.freelajobs.com.br') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the current API endpoint
   */
  public static get ENDPOINT(): string {

    if (this.IS_PRODUCTION) {
      return 'database.freelajobs.com.br/';
    } else {
      return 'localhost/Freela/Database/';
    }

  }

  /**
   * Returns url to bank logos based on the local host
   */
  public static get BANK_LOGOS_URL(): string {
    return this.STATIC_URL + '/banks/logos/images/';
  }

  /**
   * Static repository url based on the local host
   */
  private static get STATIC_URL(): string {
    if (this.IS_PRODUCTION) {
      return this.HTTP_METHOD + this.static_url;
    } else {
      return this.HTTP_METHOD + this.static_staging_url;
    }
  }

}
