import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'external',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  // Home
  {
    path: '/dashboard',
    title: 'init',
    icon: 'fa fa-th',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Finances
  {
    path: '',
    title: 'finances',
    icon: 'mdi mdi-timetable',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/plans',
        title: 'plans',
        icon: '',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor pull-right',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/goals',
        title: 'goals',
        icon: '',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor pull-right',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/tariffs',
        title: 'tariffs',
        icon: '',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor pull-right',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/points',
        title: 'points',
        icon: '',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor pull-right',
        extralink: false,
        submenu: []
      },
      // {
      //   path: '/dashboard/reports',
      //   title: 'reports',
      //   icon: '',
      //   class: '',
      //   label: '',
      //   labelClass: 'label label-rouded label-themecolor pull-right',
      //   extralink: false,
      //   submenu: []
      // },
    ]
  },
  // Notifications
  {
    path: '/dashboard/notification',
    title: 'notification',
    icon: 'mdi mdi-alert-octagram',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Partners
  {
    path: '/dashboard/companies',
    title: 'partners',
    icon: 'mdi mdi-account-check',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [] 
  },
  // Mobile User
  {
    path: '/dashboard/clients',
    title: 'mobile_users',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Tickets
  {
    path: '/dashboard/transfers',
    title: 'tickets',
    icon: 'mdi mdi-ticket-confirmation',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  // Market
  {
    path: '/dashboard/markets',
    title: 'markets',
    icon: 'mdi mdi-basket',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'internal',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  // System
  {
    path: '',
    title: 'system',
    icon: 'mdi mdi-settings',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/system/users_system',
        title: 'user_system',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/system/permissions',
        title: 'permissions',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/dashboard/system/user_profile',
        title: 'profile',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  // Terms
  {
    path: '/dashboard/terms',
    title: 'terms',
    icon: 'mdi mdi-file-document',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  // Politic
  {
    path: '/dashboard/politic',
    title: 'politicy',
    icon: 'mdi mdi-file-powerpoint',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
];
