import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../../language_configure'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @Output() changeColor: EventEmitter<any> = new EventEmitter();
  @Output() changeDarkTheme: EventEmitter<boolean> = new EventEmitter();

  @Input() color;
  @Input() showSettings;
  @Input() showDarktheme;

  @Input() config;

  public languages = [
    { 
      'value': 'pt-br',
      'name': 'portugues' 
    },
    { 
      'value': 'en-us',
      'name': 'english' 
    }
  ]

  constructor(
    public translate: TranslateService,
  ) {
    language.init(translate);
  }

  ngOnInit() {
  }

  public selectDarkTheme(element: any): void {
    if(this.showDarktheme) {
      this.color = 'bluedark';
    } else {
      this.color = 'default';
    }
    this.changeDarkTheme.emit(this.showDarktheme);
    this.changeColor.emit(this.color);
  }

  public selectLanguage(element: any): void {
    language.setLanguage(this.translate, element);
  }

  public getSelectedLanguage(element: any): void {
    language.setLanguage(this.translate, element);
  }
}
